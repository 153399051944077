import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Watch one of our investors, `}<a parentName="p" {...{
        "href": "http://uk.linkedin.com/pub/nadeem-shaikh/0/433/180"
      }}>{`Nadeem Shaikh`}</a>{` of the `}<a parentName="p" {...{
        "href": "http://www.anthemis.com/"
      }}>{`Anthemis Group`}</a>{`, talk about what's new in finance. We especially recommend the part starting at minute 8...`}</p>
    <div className="player-frame"><iframe src="https://www.youtube.com/embed/WmNyI0ZMszU?rel=0" height="390" width="640" frameBorder="0"></iframe></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      